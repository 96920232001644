<template>
  <div class="sub-container flex-sb">
    <div class="content col">
      <section class="section">


        <div v-if="tabMain['column']" >
          <hospital-health-column :hospital-id="hospitalId"></hospital-health-column>
        </div>
      </section>
    </div>
  </div>
</template>
<style></style>
<script>
import HospitalHealthColumn from "../components/HospitalHealthColumn";
export default {
  name: "HospitalColumn",
  components: {HospitalHealthColumn},
  data: () => ({
    hospital: {},
    status: null,
    statusContents: "",
    memoContents: "",
    memo: "",
    domesticMedicalCareList: [],
    domesticMedicalCareValue: [],
    overseaMedicalCareList: [],
    overseaMedicalCareValue: [],
    doctorList: [],
    tabMain: {info:false, doctor:false, column:true},
    tabSub: {application:true, calculate:false, qna:false},
    hospitalId : null,
    doctorParam: { hospitalId : null },
    doctorMode: "list",
    doctor: {},
    deleteArray : [],
    allCheckValue:[],
  }),
  mounted() {
    console.log(this.$route.path);
    console.log(this.$route.params.hospitalId);
    // this.$refs["member-qna"].list(null,this.hospitalId);
  },
  beforeMount() {
    this.hospitalId = this.$route.params.hospitalId;
    this.getHospital();

  },

  methods: {
    async getHospital() {
      const getHospitalPromise = await this.$axios.get(
        "hospital/hospital-info"
      );
      const [response] = await Promise.all([getHospitalPromise]);
      this.hospital = response.data.data;

      for(var i=0;i<this.hospital.domesticMedicalCareList.length;i++ ) {
        this.domesticMedicalCareValue.push(this.hospital.domesticMedicalCareList[i].careName);
      }
      for(i=0;i<this.hospital.overseaMedicalCareList.length;i++ ) {
        this.overseaMedicalCareValue.push(this.hospital.overseaMedicalCareList[i].careName);
      }




    },

    uploadLogo(param) {
      this.hospital.logoImage = param.url;
      document.getElementById("hospital-logo").style.backgroundRepeat = "no-repeat";
      //이미지 등록
      document.getElementById("hospital-logo").style.backgroundImage = "url('" + param.url + "')";
      //이미지 사이즈
      document.getElementById("hospital-logo").style.backgroundSize = "180px";
    },
    uploadImage(param) {
      this.hospital.coverImage = param.url;
      document.getElementById("hospital-image").style.backgroundRepeat = "no-repeat";
      //이미지 등록
      document.getElementById("hospital-image").style.backgroundImage = "url('" + param.url + "')";
      //이미지 사이즈
      document.getElementById("hospital-image").style.backgroundSize = "180px";



    },

    downloadItem(url) {
      window.open(url);
    },
    downloadItemAll() {
      for(var i=0;i<this.hospital.documentList.length;i++) {
        window.open(this.hospital.documentList[i].documentUrl);
      }
    },
    clickAllCheckbox() {
      if (this.deleteArray.length == this.doctorList.length) {
        this.deleteArray = [];
      } else {
        this.deleteArray = [];
        for(var i=0;i<this.doctorList.length;i++) {

          this.deleteArray.push(this.doctorList[i].hospitalDoctorId) ;
        }
      }
    },

    convertDateFromat(value) {
      if (value != undefined) {
        var resultDate = new Date(value);
        const offset = resultDate.getTimezoneOffset();
        resultDate = new Date(resultDate.getTime() - offset * 60 * 1000);
        return (
          resultDate.toISOString().split("T")[0] +
          " " +
          resultDate
            .toISOString()
            .split("T")[1]
            .substring(0, 8)
        );
      } else {
        return "";
      }
    },
    async updateStatus() {
      var param = {
        hospitalId: this.$route.params.hospitalId,
        status: this.status,
        contents: this.statusContents
      };
      this.$axios
        .post("hospital/status", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            // this.$router.go(0);
            this.getHospital();
            this.statusContents = "";
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    },
    async updateMemo() {
      var param = {
        hospitalId: this.$route.params.hospitalId,
        memo: this.memoContents
      };
      this.$axios
        .post("hospital/memo", param)
        .then(response => {
          if (response.data.success) {
            this.$alert.show(response.data.message);
            // this.$router.go(0);
            this.getHospital();
            this.memoContents = "";
          } else {
            this.$alert.show(response.data.message);
          }
        })
        .catch(ex => {
          if (ex.response.data.message.constructor == Object) {
            this.$alert.show(ex.response.data.message);
          }
        });
    },
    requestList() {
      this.param.page = this.nowPage - 1;
      this.param.keyword = this.keyword;
      this.param.start = this.start;
      this.param.end = this.end;

      return this.$axios.get("hospitals", { params: this.param });
    },
    async list() {
      const getListPromise = await this.requestList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.hospitalData = response.data.data;
        this.total = response.data.total;
        this.$refs["pagination"].setTotal(this.total);
        console.log("#####", this.total);
      }
    },
    requestDoctorList() {
      return this.$axios.get("hospital-doctors", { params: {hospitalId : this.hospitalId} });
    },
    async listHospitalDoctor() {
      const getListPromise = await this.requestDoctorList();
      const [response] = await Promise.all([getListPromise]);

      if (response.data.success) {
        this.doctorList = response.data.data;
      }
    },
    selectDoctor(doctor) {
      this.doctor = doctor;
      this.doctorMode = "edit";
    },
    deleteSelectedDoctor() {
      if (confirm("선택한 항목을 삭제하시겠습니까?")) {
        var ids = this.deleteArray.join(",");

        this.$axios
                .delete("hospital-doctor", { params: { ids : ids } })
                .then(response => {
                  if (response.data.success) {
                    this.$alert.show(response.data.message);
                    this.listHospitalDoctor();
                    this.deleteArray = [];
                    this.allCheckValue = [];
                  } else {
                    this.$alert.show(response.data.message);
                  }
                })
                .catch(ex => {
                  if (ex.response.data.message.constructor == Object) {
                    this.$alert.show(ex.response.data.message);
                  }
                });

      }
    },

  }
};
</script>
